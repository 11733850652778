import React from "react";
import styles from "./MainText.module.css";

interface MainTextProps {
  children: React.ReactNode;
  fontSize?: string;
  color?: string;
  fontWeight?: number;
  textAlign?: any;
}

const MainText: React.FC<MainTextProps> = ({ children, ...props }) => {
  return (
    <p
      className={styles.mainText}
      style={{
        fontSize: props.fontSize,
        color: props.color,
        fontWeight: props.fontWeight,
        textAlign: props.textAlign,
      }}
    >
      {children}
    </p>
  );
};

export default MainText;
