import React, { useContext, useEffect, useState } from "react";

import styles from "./Quiz.module.css"; // Import css modules stylesheet as styles
import {
  MdContentCopy,
  MdOutlineEdit,
  MdDone,
  MdDeleteOutline,
} from "react-icons/md";
import { IoMdEyeOff, IoMdEye } from "react-icons/io";
import toast from "react-hot-toast";
import { GoogleAnalyticsContext } from "../../Hooks/GoogleAnalyticsContext";
import VerticalFlexContainer from "../../Components/Layout/VerticalFlexContainer/VerticalFlexContainer";
import HorizontalFlexContainer from "../../Components/Layout/HorizontalFlexContainer/HorizontalFlexContainer";
import Spacer from "../../Components/Layout/Spacer/Spacer";
import { useNavigate, useParams } from "react-router-dom";
import { useAuthenticatedFetch, useFetch } from "../../Hooks/useFetch";
import config from "../../config";
import PageWrapper from "../../Components/Layout/PageWrapper/PageWrapper";
import NavigationBar from "../../Components/HighLevelComponents/NavigationBar/NavigationBar";
import TitleText from "../../Components/Text/TitleText/TitleText";
import { YippityUserContext } from "../../Hooks/YippityUserContext";
import { useFirebaseAuth } from "../../Hooks/FirebaseAuth";
import Button from "../../Components/Input/Button/Button";
import MainText from "../../Components/Text/MainText/MainText";

interface Question {
  questionText: string;
  answerText: string;
  canEdit: boolean;
  id: number;
}

interface Quiz {
  name: string;
  authorId: number;
  id: number;
}

const QuestionAnswerCard: React.FC<Question> = (props) => {
  const [editingMode, setEditingMode] = useState(false);

  const [question, setQuestion] = useState(props.questionText);
  const [answer, setAnswer] = useState(props.answerText);

  const [isDeleted, setIsDeleted] = useState(false);
  const { logEvent } = useContext(GoogleAnalyticsContext);

  const { res: updateQuestionResponse, callAPI } = useAuthenticatedFetch({
    url: config.API_URI + `/question/${props.id}`,
    method: "PUT",
  });

  const { res: deleteQuestionResponse, callAPI: deleteQuestion } =
    useAuthenticatedFetch({
      url: config.API_URI + `/question/${props.id}`,
      method: "DELETE",
    });

  const onClickCopy = async (e: any) => {
    e.preventDefault();
    const finalText = answer ? question + "\n" + answer : question;
    await navigator.clipboard.writeText(finalText);
    toast.success("Copied question and answer to clipboard");
    logEvent("Interaction", "Copy");
  };

  const onClickEdit = (e: any) => {
    e.preventDefault();
    setEditingMode(true);
    logEvent("Interaction", "Edit");
  };

  const onClickDone = (e: any) => {
    e.preventDefault();
    setEditingMode(false);
    logEvent("Interaction", "Done");
    callAPI(
      JSON.stringify({
        questionText: question,
        answerText: answer,
      })
    );
  };

  const onClickDelete = (e: any) => {
    e.preventDefault();
    setIsDeleted(true);
    deleteQuestion();
  };

  const onEditingModeIcons = (
    <MdDone size={24} onClick={onClickDone} className={styles.iconButton} />
  );

  const nonEditingModeIcons = (
    <>
      <MdContentCopy className={styles.iconButton} onClick={onClickCopy} />
      <Spacer width="8px" />
      {props.canEdit && (
        <>
          <Spacer width="8px" />
          <MdOutlineEdit className={styles.iconButton} onClick={onClickEdit} />
          <Spacer width="8px" />
          <MdDeleteOutline
            className={styles.iconButton}
            onClick={onClickDelete}
          />
        </>
      )}
    </>
  );

  if (isDeleted) {
    return <></>;
  }

  return (
    <VerticalFlexContainer
      backgroundColor="rgba(0, 0, 0, 0.125)"
      padding="20px"
      borderRadius="10px"
      marginBottom="10px"
      width="100%"
      alignItems="flex-start"
      justifyContent="flex-start"
    >
      <HorizontalFlexContainer justifyContent="space-between" width="100%">
        {editingMode ? (
          <textarea
            className={styles.editingField}
            value={question}
            onChange={(e) => setQuestion(e.target.value)}
          />
        ) : (
          <p className={styles.questionText}>{question}</p>
        )}
        <HorizontalFlexContainer>
          {!editingMode && nonEditingModeIcons}
        </HorizontalFlexContainer>
      </HorizontalFlexContainer>
      <Spacer height="10px" />
      {answer &&
        (editingMode ? (
          <textarea
            className={styles.editingField}
            value={answer}
            onChange={(e) => setAnswer(e.target.value)}
          />
        ) : (
          <p className={styles.resultText}>{answer}</p>
        ))}
      {editingMode && (
        <>
          <Spacer height="8px" />
          <HorizontalFlexContainer width="100%" justifyContent="flex-end">
            {onEditingModeIcons}
          </HorizontalFlexContainer>
        </>
      )}
    </VerticalFlexContainer>
  );
};

function QuizHeader({
  quizData,
  canEdit,
  questions,
}: {
  quizData?: Quiz;
  canEdit: boolean;
  questions: Question[];
}) {
  const [name, setName] = useState(quizData?.name || "");

  const { res: updateQuizResponse, callAPI } = useAuthenticatedFetch({
    url: config.API_URI + `/quiz/${quizData?.id}`,
    method: "PUT",
  });

  const navigate = useNavigate();
  const [isEditing, setIsEditing] = useState(false);

  const { logEvent } = useContext(GoogleAnalyticsContext);

  useEffect(() => {
    if (updateQuizResponse.statusCode === 200) {
      const { quiz } = updateQuizResponse.data;
      setName(quiz.name);
    }
  }, [updateQuizResponse]);

  useEffect(() => {
    if (quizData) {
      setName(quizData.name);
    }
  }, [quizData]);

  const onClickEdit = (e: any) => {
    e.preventDefault();
    setIsEditing(true);
  };

  const onClickDone = (e: any) => {
    e.preventDefault();
    callAPI(
      JSON.stringify({
        name,
      })
    );
    setIsEditing(false);
  };

  return (
    <HorizontalFlexContainer
      justifyContent="space-between"
      width="100%"
      alignItems="center"
      padding="20px"
    >
      {isEditing ? (
        <>
          <form
            onSubmit={onClickDone}
            style={{
              flex: 1,
            }}
          >
            <input
              value={name}
              placeholder="Quiz name"
              onChange={(e) => setName(e.target.value)}
              style={{
                width: "100%",
              }}
            />
          </form>
          <MdDone
            size={24}
            onClick={onClickDone}
            className={styles.iconButton}
          />
        </>
      ) : (
        <VerticalFlexContainer width="100%" alignItems="flex-end">
          <HorizontalFlexContainer alignItems="center">
            <HorizontalFlexContainer
              onClick={async () => {
                await navigator.clipboard.writeText(
                  `https://yippity.io/quiz/${quizData?.id}`
                );
                toast.success("Copied link to clipboard! Your quiz is live!");
                logEvent("Interaction", "Share");
              }}
              cursor="pointer"
            >
              <MainText>Share</MainText>
            </HorizontalFlexContainer>
            <Spacer width="20px" />
            {questions.length && (
              <Button
                onClick={() => {
                  logEvent("Interaction", "Test Mode");
                  navigate(`test`);
                }}
              >
                Test Mode (beta)
              </Button>
            )}
          </HorizontalFlexContainer>
          <HorizontalFlexContainer
            alignItems="center"
            justifyContent="space-between"
            width="100%"
          >
            <TitleText textAlign={"left"}>{name}</TitleText>
            {canEdit && (
              <HorizontalFlexContainer>
                <MdOutlineEdit
                  className={styles.iconButton}
                  onClick={onClickEdit}
                  size={30}
                />
              </HorizontalFlexContainer>
            )}
          </HorizontalFlexContainer>
        </VerticalFlexContainer>
      )}
    </HorizontalFlexContainer>
  );
}

export default function Quiz() {
  const { quizId } = useParams();
  const [questions, setQuestions] = useState<Question[]>([]);
  const [quiz, setQuiz] = useState<Quiz>();
  const { res, callAPI } = useFetch({
    url: config.API_URI + `/quiz/${quizId}`,
    method: "GET",
  });
  const firebaseAuth = useFirebaseAuth();
  const { user } = useContext(YippityUserContext);
  const canEdit =
    !!firebaseAuth && !!user && !!quiz && user.id === quiz.authorId;

  useEffect(() => {
    callAPI();
  }, []);

  useEffect(() => {
    if (res.statusCode === 200) {
      const { questions, quiz } = res.data;
      setQuestions(questions);
      setQuiz(quiz);
    }
  }, [res]);

  return (
    <PageWrapper noBackground>
      <NavigationBar />
      <HorizontalFlexContainer justifyContent="center" flex={1}>
        <VerticalFlexContainer
          justifyContent="center"
          alignItems="center"
          height="100%"
        >
          <Spacer height="20px" />
          <QuizHeader quizData={quiz} canEdit={canEdit} questions={questions} />
          <VerticalFlexContainer
            justifyContent="flex-start"
            alignItems="flex-start"
            overflowY="scroll"
            maxWidth="600px"
            margin="15px"
          >
            {questions.map((question, i) => (
              <QuestionAnswerCard
                key={i}
                questionText={question.questionText}
                answerText={question.answerText}
                canEdit={canEdit}
                id={question.id}
              />
            ))}
          </VerticalFlexContainer>
        </VerticalFlexContainer>
      </HorizontalFlexContainer>
    </PageWrapper>
  );
}
