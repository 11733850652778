import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import NavigationBar from "../../Components/HighLevelComponents/NavigationBar/NavigationBar";
import HorizontalFlexContainer from "../../Components/Layout/HorizontalFlexContainer/HorizontalFlexContainer";
import PageWrapper from "../../Components/Layout/PageWrapper/PageWrapper";
import Spacer from "../../Components/Layout/Spacer/Spacer";
import VerticalFlexContainer from "../../Components/Layout/VerticalFlexContainer/VerticalFlexContainer";
import MainText from "../../Components/Text/MainText/MainText";
import TitleText from "../../Components/Text/TitleText/TitleText";
import config from "../../config";
import { useAuthenticatedFetch } from "../../Hooks/useFetch";

interface Quiz {
  name: string;
  authorId: number;
  id: number;
  timeCreated: Date;
}

function QuizTile({ quiz }: { quiz: Quiz }) {
  const navigate = useNavigate();
  return (
    <HorizontalFlexContainer
      onClick={() => navigate(`${quiz.id}`)}
      backgroundColor="rgba(0, 0, 0, 0.1)"
      width="200px"
      padding="8px"
      margin="4px"
      borderRadius="12px"
      cursor="pointer"
    >
      <MainText fontWeight={700}>{quiz.name}</MainText>
    </HorizontalFlexContainer>
  );
}

function Quizzes() {
  const [quizzes, setQuizzes] = useState<Quiz[]>([]);
  const { res, callAPI } = useAuthenticatedFetch({
    url: config.API_URI + `/quiz`,
    method: "GET",
  });

  useEffect(() => {
    callAPI();
  }, []);

  useEffect(() => {
    if (res.statusCode === 200) {
      setQuizzes(res.data.quizzes);
    }
  }, [res]);
  return (
    <PageWrapper>
      <NavigationBar />
      <VerticalFlexContainer width="100%" paddingLeft="10%" paddingRight="10%">
        <TitleText textAlign="left">My Quizzes</TitleText>
        <Spacer height="24px" />
        <HorizontalFlexContainer flexWrap="wrap">
          {quizzes.map((quiz, index) => (
            <QuizTile quiz={quiz} />
          ))}
        </HorizontalFlexContainer>
      </VerticalFlexContainer>
    </PageWrapper>
  );
}

export default Quizzes;
