import * as React from "react";
import { createContext, useEffect } from "react";
import config from "../config";
import { useFirebaseAuth } from "./FirebaseAuth";
import { useAuthenticatedFetch } from "./useFetch";

type YippityUser = {
  username: string;
  id: number;
  subscriptionType: number;
  quizzesCreated: number;
} | null;

interface YippityUserContext {
  user: YippityUser;
  isSubscribed: boolean;
  refetch: () => void;
}

export const YippityUserContext = createContext<YippityUserContext>({
  user: null,
  refetch: () => {},
  isSubscribed: false,
});

type Props = {
  children?: React.ReactNode;
};

export const YippityUserProvider: React.FC<Props> = ({ children }) => {
  const cached = localStorage.getItem("yippityUser");
  const [user, setUser] = React.useState<YippityUser>(
    cached ? JSON.parse(cached) : null
  );

  const firebaseAuth = useFirebaseAuth();

  const { res, callAPI } = useAuthenticatedFetch({
    url: config.API_URI + `/user`,
    method: "GET",
  });

  const isSubscribed = !user || (!!user && user.subscriptionType === 1);

  useEffect(() => {
    if (firebaseAuth) {
      callAPI();
    }
  }, [firebaseAuth]);

  useEffect(() => {
    if (res.statusCode === 200) {
      setUser(res.data);
      localStorage.setItem("yippityUser", JSON.stringify(res.data));
    } else {
      setUser(null);
    }
  }, [res]);

  return (
    <YippityUserContext.Provider
      value={{ user, refetch: callAPI, isSubscribed }}
    >
      {children}
    </YippityUserContext.Provider>
  );
};
