import YippityLogo from "../../../images/logo.png";
export default function Logo({ size }: { size: string }) {
  return (
    <div
      style={{
        width: size,
        height: size,
      }}
    >
      <img
        src={YippityLogo}
        style={{
          width: size,
          height: size,
        }}
      />
    </div>
  );
}

Logo.defaultProps = {
  size: "200px",
};
