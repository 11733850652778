import { useEffect, useState } from "react";
import TextInput from "../../Components/Input/TextInput/TextInput";
import HorizontalFlexContainer from "../../Components/Layout/HorizontalFlexContainer/HorizontalFlexContainer";
import PageWrapper from "../../Components/Layout/PageWrapper/PageWrapper";
import VerticalFlexContainer from "../../Components/Layout/VerticalFlexContainer/VerticalFlexContainer";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import styles from "./Welcome.module.css";
import Spacer from "../../Components/Layout/Spacer/Spacer";
import TitleText from "../../Components/Text/TitleText/TitleText";
import Button from "../../Components/Input/Button/Button";
import NavigationBar from "../../Components/HighLevelComponents/NavigationBar/NavigationBar";
import MainText from "../../Components/Text/MainText/MainText";
import config from "../../config";
import { useNavigate } from "react-router-dom";
import { useAuthenticatedFetch } from "../../Hooks/useFetch";
import toast from "react-hot-toast";
export default function Welcome() {
  const [username, setUsername] = useState("");

  const [startDate, setStartDate] = useState(new Date());
  const navigate = useNavigate();
  const { res: userResponse, callAPI: createUser } = useAuthenticatedFetch({
    url: config.API_URI + "/user",
    method: "POST",
  });

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    createUser(
      JSON.stringify({
        username,
        month: startDate.getMonth() + 1,
        day: startDate.getDate(),
        year: startDate.getFullYear(),
      })
    );
  };

  useEffect(() => {
    if (userResponse.statusCode === 200) {
      navigate("/home");
    }
    if (userResponse.statusCode === 409) {
      toast.error("Username already in use");
    }

    if (userResponse.statusCode === 400) {
      toast.error(
        "Username can only contain letters, numbers and underscores and must be at least 6 characters long"
      );
    }
  }, [userResponse]);

  return (
    <PageWrapper>
      <NavigationBar />
      <VerticalFlexContainer
        justifyContent="center"
        alignItems="center"
        minHeight="80vh"
      >
        <VerticalFlexContainer alignItems="center" maxWidth="300px">
          <TitleText>Welcome to Yippity! 👋</TitleText>
          <Spacer height="18px" />
          <HorizontalFlexContainer width="100%">
            <MainText>How should we call you?</MainText>
          </HorizontalFlexContainer>
          <TextInput
            placeholder="Username"
            onChange={(e) => setUsername(e.target.value)}
            value={username}
          />
          <Spacer height="4px" />
          <HorizontalFlexContainer width="100%">
            <MainText>What's your date of birth?</MainText>
          </HorizontalFlexContainer>
          <HorizontalFlexContainer>
            <DatePicker
              selected={startDate}
              onChange={(date: Date) => setStartDate(date)}
              className={styles.webflowStyleInput}
              placeholderText="Date of birth"
            />
          </HorizontalFlexContainer>
          <Spacer height="12px" />
          <Button onClick={handleSubmit} width="100%">
            Continue
          </Button>
        </VerticalFlexContainer>
      </VerticalFlexContainer>
    </PageWrapper>
  );
}
