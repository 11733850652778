import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PageWrapper from "../../Components/Layout/PageWrapper/PageWrapper";
import VerticalFlexContainer from "../../Components/Layout/VerticalFlexContainer/VerticalFlexContainer";
import MainText from "../../Components/Text/MainText/MainText";
import useInterval from "../../Hooks/useInterval";
import { YippityUserContext } from "../../Hooks/YippityUserContext";

function Success() {
  const { isSubscribed, refetch, user } = useContext(YippityUserContext);

  const navigate = useNavigate();
  useInterval(() => {
    refetch();
  }, 2000);

  useEffect(() => {
    if (isSubscribed) {
      navigate("/home");
    }
  }, [isSubscribed]);

  return (
    <PageWrapper>
      <VerticalFlexContainer
        justifyContent="center"
        alignItems="center"
        height="100%"
        width="100%"
        padding="20px"
      >
        <MainText>Payment Successful, Redirecting...</MainText>
      </VerticalFlexContainer>
    </PageWrapper>
  );
}

export default Success;
