import { useContext } from "react";
import { YippityUserContext } from "./YippityUserContext";
import config from "../config";

const useCanCreateQuiz = () => {
  const { user } = useContext(YippityUserContext);

  if (!user) {
    return { usageRemaining: 0, usageConsumed: true };
  }
  const usageRemaining = Math.max(
    0,
    config.MONTHLY_ALLOWANCE - user.quizzesCreated
  );
  return user.subscriptionType === 1 || usageRemaining > 0;
};

export default useCanCreateQuiz;
