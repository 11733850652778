import { useContext } from "react";
import config from "../config";
import { YippityUserContext } from "./YippityUserContext";

const useFreeSubscriptionStatus = () => {
  const { user } = useContext(YippityUserContext);
  if (!user) {
    return { usageRemaining: 0, usageConsumed: true };
  }
  const usageRemaining = Math.max(
    0,
    config.MONTHLY_ALLOWANCE - user.quizzesCreated
  );
  return { usageRemaining, usageConsumed: usageRemaining === 0 };
};

export default useFreeSubscriptionStatus;
