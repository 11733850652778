import { HashLoader } from "react-spinners";
import HorizontalFlexContainer from "../../Layout/HorizontalFlexContainer/HorizontalFlexContainer";
import styles from "./Button.module.css";
interface ButtonProps {
  onClick: React.MouseEventHandler<HTMLDivElement>;
  children: any;
  width?: string;
  height?: string;
  disabled?: boolean;
  loading?: boolean;
}

export default function Button(props: ButtonProps) {
  return (
    <HorizontalFlexContainer
      onClick={!props.disabled && !props.loading ? props.onClick : undefined}
      width={props.width}
      height={props.height}
      className={
        props.disabled || props.loading ? styles.disabled : styles.enabled
      }
    >
      {props.loading ? (
        <HashLoader color="#FFC60B" size={24} />
      ) : (
        <p className={styles.buttonText}>{props.children.toUpperCase()}</p>
      )}
    </HorizontalFlexContainer>
  );
}
