import { useContext, useEffect, useState } from "react";
import * as firebaseui from "firebaseui";
import { auth } from "../../config/firebase-config";
import {
  createUserWithEmailAndPassword,
  GoogleAuthProvider,
} from "firebase/auth";
import "firebaseui/dist/firebaseui.css";
import VerticalFlexContainer from "../../Components/Layout/VerticalFlexContainer/VerticalFlexContainer";
import PageWrapper from "../../Components/Layout/PageWrapper/PageWrapper";
import Button from "../../Components/Input/Button/Button";
import NavigationBar from "../../Components/HighLevelComponents/NavigationBar/NavigationBar";
import TextInput from "../../Components/Input/TextInput/TextInput";
import Spacer from "../../Components/Layout/Spacer/Spacer";
import HorizontalFlexContainer from "../../Components/Layout/HorizontalFlexContainer/HorizontalFlexContainer";
import TitleText from "../../Components/Text/TitleText/TitleText";
import { validateEmail, validatePassword } from "../../utils/inputValidation";
import { Link, useNavigate } from "react-router-dom";
import MainText from "../../Components/Text/MainText/MainText";
import toast from "react-hot-toast";
import { YippityUserContext } from "../../Hooks/YippityUserContext";
import { MdInfoOutline } from "react-icons/md";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css"; // optional
import "tippy.js/themes/light.css";

const Register = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const navigate = useNavigate();

  const { refetch } = useContext(YippityUserContext);

  useEffect(() => {
    const uiConfig = {
      signInSuccessUrl: "/authenticated",
      signInFlow: "popup",
      signInOptions: [
        // Leave the lines as is for the providers you want to offer your users.
        GoogleAuthProvider.PROVIDER_ID,
      ],
    };
    const ui =
      firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(auth);

    ui.start("#firebaseui-auth-container", uiConfig);
  }, []);

  const signUp = async (e: any) => {
    if (!validateEmail(email)) {
      return toast.error("Please enter a valid email!");
    }

    if (!validatePassword(password)) {
      return toast.error(
        "Your password must be have at least: \n - 8 characters long \n - 1 uppercase & 1 lowercase character \n - 1 number"
      );
    }

    if (password != confirmPassword) {
      return toast.error("Passwords do not match!");
    }

    e.preventDefault();
    try {
      const userCredentials = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      if (userCredentials.user) {
        await refetch();
        navigate("/authenticated");
      }
    } catch (e) {
      toast.error(`User already exists! Sign in instead`);
      console.error(e);
    }
  };

  return (
    <PageWrapper>
      <NavigationBar />
      <VerticalFlexContainer
        width="100%"
        flex={1}
        justifyContent="center"
        alignItems="center"
        paddingBottom="20vh"
      >
        <TitleText fontSize="30px">Create your account</TitleText>
        <Spacer height="20px" />
        <VerticalFlexContainer maxWidth="400px" height="100%">
          <MainText fontSize="12px">Email</MainText>
          <TextInput
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            type="email"
          />
          <Spacer height="8px" />
          <MainText fontSize="12px">Password</MainText>{" "}
          <TextInput
            placeholder="Password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            logo={
              <Tippy
                theme="light"
                content={
                  <div>
                    <MainText>Password must contain at least:</MainText>
                    <ul
                      style={{
                        margin: "0",
                        paddingLeft: "20px",
                      }}
                    >
                      <li>
                        <MainText>8 characters long</MainText>
                      </li>
                      <li>
                        <MainText>1 uppercase</MainText>
                      </li>
                      <li>
                        <MainText>1 number</MainText>
                      </li>
                    </ul>
                  </div>
                }
              >
                <div>
                  <MdInfoOutline size={20} color="#3c4250" />
                </div>
              </Tippy>
            }
          />
          <Spacer height="8px" />
          <MainText fontSize="12px">Confirm password</MainText>{" "}
          <TextInput
            placeholder="Confirm Password"
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          <Spacer height="8px" />
          <Button onClick={signUp} width="100%" height="42px">
            Sign up
          </Button>
          <Spacer height="12px" />
          <HorizontalFlexContainer width="100%" alignItems="center">
            <HorizontalFlexContainer
              borderBottom="1px solid #3c4250"
              width="100%"
              flex={1}
              marginRight="4px"
            >
              <></>
            </HorizontalFlexContainer>
            <span
              style={{
                fontWeight: "700",
                color: "#3c4250",
              }}
            >
              OR
            </span>
            <HorizontalFlexContainer
              borderBottom="1px solid #3c4250"
              width="100%"
              flex={1}
              marginLeft="4px"
            >
              <></>
            </HorizontalFlexContainer>
          </HorizontalFlexContainer>
          <div id="firebaseui-auth-container"></div>
        </VerticalFlexContainer>
      </VerticalFlexContainer>
    </PageWrapper>
  );
};

export default Register;
