import React from "react";
import styles from "./TitleText.module.css";

interface TitleTextProps {
  children: React.ReactNode;
  fontSize?: string;
  textAlign?: any;
  color?: string;
}

const TitleText: React.FC<TitleTextProps> = ({ children, ...props }) => {
  return (
    <h2
      className={styles.titleText}
      style={{
        fontSize: props.fontSize,
        textAlign: props.textAlign,
        color: props.color,
      }}
    >
      {children}
    </h2>
  );
};

export default TitleText;
