import { useEffect, useState } from "react";
import * as firebaseui from "firebaseui";
import { auth } from "../../config/firebase-config";
import { GoogleAuthProvider, signInWithEmailAndPassword } from "firebase/auth";
import "firebaseui/dist/firebaseui.css";
import VerticalFlexContainer from "../../Components/Layout/VerticalFlexContainer/VerticalFlexContainer";
import PageWrapper from "../../Components/Layout/PageWrapper/PageWrapper";
import Button from "../../Components/Input/Button/Button";
import NavigationBar from "../../Components/HighLevelComponents/NavigationBar/NavigationBar";
import TextInput from "../../Components/Input/TextInput/TextInput";
import Spacer from "../../Components/Layout/Spacer/Spacer";
import HorizontalFlexContainer from "../../Components/Layout/HorizontalFlexContainer/HorizontalFlexContainer";
import TitleText from "../../Components/Text/TitleText/TitleText";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const uiConfig: firebaseui.auth.Config = {
      signInSuccessUrl: "/authenticated",
      signInFlow: "popup",
      signInOptions: [
        // Leave the lines as is for the providers you want to offer your users.
        GoogleAuthProvider.PROVIDER_ID,
      ],
    };
    const ui =
      firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(auth);

    ui.start("#firebaseui-auth-container", uiConfig);
  }, []);

  const signIn = async (e: any) => {
    e.preventDefault();
    try {
      const userCredentials = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      if (userCredentials.user) {
        navigate("/authenticated");
      }
    } catch (e) {
      toast.error("Invalid email or password");
      console.error(e);
    }
  };

  return (
    <PageWrapper>
      <NavigationBar />
      <VerticalFlexContainer
        width="100%"
        flex={1}
        justifyContent="center"
        alignItems="center"
        paddingBottom="20vh"
      >
        <TitleText fontSize="30px">Sign into your account</TitleText>
        <Spacer height="20px" />
        <VerticalFlexContainer
          maxWidth="400px"
          height="100%"
          justifyContent="center"
          alignItems="center"
        >
          <TextInput
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            type="email"
          />
          <Spacer height="8px" />
          <TextInput
            placeholder="Password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Spacer height="8px" />
          <Button
            onClick={signIn}
            width="100%"
            height="42px"
            disabled={!(Boolean(email.length) && Boolean(password.length))}
          >
            Sign in
          </Button>
          <Spacer height="12px" />
          <HorizontalFlexContainer width="100%" alignItems="center">
            <HorizontalFlexContainer
              borderBottom="1px solid #3c4250"
              width="100%"
              flex={1}
              marginRight="4px"
            >
              <></>
            </HorizontalFlexContainer>
            <span
              style={{
                fontWeight: "700",
                color: "#3c4250",
              }}
            >
              OR
            </span>
            <HorizontalFlexContainer
              borderBottom="1px solid #3c4250"
              width="100%"
              flex={1}
              marginLeft="4px"
            >
              <></>
            </HorizontalFlexContainer>
          </HorizontalFlexContainer>
          <div id="firebaseui-auth-container"></div>
        </VerticalFlexContainer>
      </VerticalFlexContainer>
    </PageWrapper>
  );
};

export default Login;
