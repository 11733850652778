import { useState } from "react";
import { useFirebaseAuth } from "./FirebaseAuth";

interface useFetchProps {
  url: string;
  method?: string;
  payload?: string;
}

interface useFetchResponse {
  data?: any | null;
  error?: any | null;
  statusCode?: number | null;
}

export const useAuthenticatedFetch = ({ url, method }: useFetchProps) => {
  const user = useFirebaseAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [res, setRes] = useState<useFetchResponse>({
    data: null,
    error: null,
    statusCode: null,
  });

  const callAPI = async (payload?: string) => {
    if (user) {
      try {
        setIsLoading(true);
        const response = await fetch(url, {
          method,
          headers: {
            authorization: `Bearer ${await user.getIdToken()}`,
            "Content-type": "application/json",
          },
          body: payload,
        });
        if (response.status === 200) {
          setRes({
            data: await response.json(),
            error: null,
            statusCode: 200,
          });
        } else {
          setRes((prevState) => ({
            ...prevState,
            statusCode: response.status,
          }));
        }
      } catch (error) {
        setRes({ data: null, error, statusCode: null });
      }
      setIsLoading(false);
    } else {
      setRes((prevState) => ({
        ...prevState,
        error: "You are not authorized",
      }));
    }
  };

  return { res, callAPI, isLoading };
};

export const useFetch = ({ url, method }: useFetchProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const user = useFirebaseAuth();

  const [res, setRes] = useState<useFetchResponse>({
    data: null,
    error: null,
    statusCode: null,
  });

  const callAPI = async (payload?: string) => {
    try {
      setIsLoading(true);
      const response = await fetch(url, {
        method,
        headers: {
          authorization: user ? `Bearer ${await user.getIdToken()}` : "",
          "Content-type": "application/json",
        },
        body: payload,
      });
      if (response.status === 200) {
        return setRes({
          data: await response.json(),
          error: null,
          statusCode: 200,
        });
      } else {
        setRes((prevState) => ({
          ...prevState,
          statusCode: response.status,
        }));
      }
    } catch (error) {
      setRes({ data: null, error, statusCode: null });
    }
    setIsLoading(false);
  };

  return { res, callAPI, isLoading };
};
