import { FirebaseAuthProvider } from "./Hooks/FirebaseAuth";
import { GoogleAnalyticsContextProvider } from "./Hooks/GoogleAnalyticsContext";
import { ShowEmailFormContextProvider } from "./Hooks/ShowEmailFormContext";
import RouterEntry from "./RouterEntry";
import { Toaster } from "react-hot-toast";
import { YippityUserProvider } from "./Hooks/YippityUserContext";
import { BrowserRouter } from "react-router-dom";
function App() {
  return (
    <BrowserRouter>
      <FirebaseAuthProvider>
        <YippityUserProvider>
          <Toaster
            toastOptions={{
              style: {
                fontSize: "12px",
              },
            }}
          />
          <ShowEmailFormContextProvider>
            <GoogleAnalyticsContextProvider>
              <RouterEntry />
            </GoogleAnalyticsContextProvider>
          </ShowEmailFormContextProvider>
        </YippityUserProvider>
      </FirebaseAuthProvider>
    </BrowserRouter>
  );
}

export default App;
