import React from "react";
import styles from "./PageWrapper.module.css";
import background from "../../../images/colorful-background.png";

interface PageWrapperProps {
  children?: any;
  noBackground?: boolean;
}

const PageWrapper: React.FC<PageWrapperProps> = ({
  children,
  noBackground,
}) => {
  return (
    <div
      className={styles.wrapper}
      style={!!noBackground ? {} : { backgroundImage: `url(${background})` }}
    >
      {children}
    </div>
  );
};

export default PageWrapper;
