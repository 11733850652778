import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import NavigationBar from "../../Components/HighLevelComponents/NavigationBar/NavigationBar";
import HorizontalFlexContainer from "../../Components/Layout/HorizontalFlexContainer/HorizontalFlexContainer";

import PageWrapper from "../../Components/Layout/PageWrapper/PageWrapper";
import Spacer from "../../Components/Layout/Spacer/Spacer";
import VerticalFlexContainer from "../../Components/Layout/VerticalFlexContainer/VerticalFlexContainer";
import MainText from "../../Components/Text/MainText/MainText";
import TitleText from "../../Components/Text/TitleText/TitleText";
import config from "../../config";
import { useAuthenticatedFetch } from "../../Hooks/useFetch";
import { FaArrowLeft } from "react-icons/fa";
import { GoogleAnalyticsContext } from "../../Hooks/GoogleAnalyticsContext";

interface Report {
  id: number;
  quizId: number;
  userId: number;
  report: string;
  questionsAttempted: number;
  marks: number;
  timeCreated: Date;
}

function Report() {
  const { quizId, reportId } = useParams();
  const { res, callAPI } = useAuthenticatedFetch({
    url: config.API_URI + `/quiz/${quizId}/report/${reportId}`,
    method: "GET",
  });
  const [report, setReport] = useState<Report>();
  const navigate = useNavigate();
  const { logEvent } = useContext(GoogleAnalyticsContext);
  useEffect(() => {
    callAPI();
  }, []);

  useEffect(() => {
    if (res.statusCode === 200) {
      const { report } = res.data;
      setReport(report);
    }
  }, [res]);
  console.log(report);
  return (
    <PageWrapper noBackground>
      <NavigationBar />
      <VerticalFlexContainer alignItems="center" padding="8px">
        <VerticalFlexContainer maxWidth="700px">
          <HorizontalFlexContainer
            alignItems="center"
            cursor="pointer"
            onClick={() => {
              navigate(`/quiz/${quizId}`);
            }}
          >
            <FaArrowLeft />
            <Spacer width="4px" />
            <MainText fontWeight={500}>Back to Quiz</MainText>
          </HorizontalFlexContainer>
          <Spacer height="12px" />
          {report?.marks != undefined && report.questionsAttempted && (
            <TitleText textAlign="left">
              Final Score: {(report?.marks / report?.questionsAttempted) * 100}%
            </TitleText>
          )}
          <Spacer height="12px" />
          {report?.report && groupQuestions(report.report)}
        </VerticalFlexContainer>
      </VerticalFlexContainer>
    </PageWrapper>
  );
}

function groupQuestions(inputString: string): any[] {
  const questions = inputString.split("\n\n");

  const questionRegex = /^Question \d+: .+?$/gms;
  const correctAnswerRegex = /^Correct Answer \d+: .+?$/gms;
  const studentAnswerRegex = /^Student Answer \d+: .+?$/gms;
  const verdictRegex = /^Verdict \d+: .+?$/gms;
  const reasonRegex = /^Reason \d+: .+?$/gms;

  return questions.map((question, index) => {
    const questionMatch = question.match(questionRegex);
    const correctAnswerMatch = question.match(correctAnswerRegex);
    const studentAnswerMatch = question.match(studentAnswerRegex);
    const verdictMatch = question.match(verdictRegex);
    const reasonMatch = question.match(reasonRegex);

    return (
      <VerticalFlexContainer
        key={index}
        backgroundColor="rgba(0, 0, 0, 0.05)"
        padding="20px"
        borderRadius="10px"
        marginBottom="10px"
        width="100%"
        alignItems="flex-start"
        justifyContent="flex-start"
      >
        {questionMatch && (
          <MainText fontWeight={700} fontSize="16px">
            {questionMatch[0]}
          </MainText>
        )}
        {correctAnswerMatch && (
          <MainText fontSize="16px">{correctAnswerMatch[0]}</MainText>
        )}
        {studentAnswerMatch && (
          <MainText fontSize="16px">{studentAnswerMatch[0]}</MainText>
        )}

        {verdictMatch && (
          <MainText
            fontSize="16px"
            fontWeight={500}
            color={formatVerdictColor(verdictMatch[0])}
          >
            {verdictMatch[0]}
          </MainText>
        )}

        {reasonMatch && (
          <MainText fontSize="16px" fontWeight={500}>
            {reasonMatch[0]}
          </MainText>
        )}
      </VerticalFlexContainer>
    );
  });
}

function formatVerdictColor(verdict: string) {
  if (verdict.includes("Partially Correct")) {
    return "orange";
  }
  if (verdict.includes("Correct")) {
    return "green";
  }

  return "red";
}

export default Report;
