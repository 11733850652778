import { useContext } from "react";
import useFreeSubscriptionStatus from "../../../Hooks/useFreeSubscriptionStatus";
import { YippityUserContext } from "../../../Hooks/YippityUserContext";
import HorizontalFlexContainer from "../../Layout/HorizontalFlexContainer/HorizontalFlexContainer";
import MainText from "../../Text/MainText/MainText";

function TrialCounter() {
  const { usageRemaining } = useFreeSubscriptionStatus();
  return (
    <HorizontalFlexContainer>
      <MainText>Free Quizzes Remaining: {usageRemaining}</MainText>
    </HorizontalFlexContainer>
  );
}

export default TrialCounter;
