import { FormEvent, useContext, useEffect, useState } from "react";
import { HashLoader } from "react-spinners";

import styles from "./Home.module.css";
import QuizStyles from "../Quiz/Quiz.module.css";
import PageWrapper from "../../Components/Layout/PageWrapper/PageWrapper";
import NavigationBar from "../../Components/HighLevelComponents/NavigationBar/NavigationBar";
import VerticalFlexContainer from "../../Components/Layout/VerticalFlexContainer/VerticalFlexContainer";
import config from "../../config";
import { GoogleAnalyticsContext } from "../../Hooks/GoogleAnalyticsContext";
import HorizontalFlexContainer from "../../Components/Layout/HorizontalFlexContainer/HorizontalFlexContainer";
import Button from "../../Components/Input/Button/Button";
import TitleText from "../../Components/Text/TitleText/TitleText";
import { useAuthenticatedFetch } from "../../Hooks/useFetch";
import { useNavigate } from "react-router-dom";
import Spacer from "../../Components/Layout/Spacer/Spacer";
import TextInput from "../../Components/Input/TextInput/TextInput";
import { BsFillArrowRightCircleFill } from "react-icons/bs";
import { YippityUserContext } from "../../Hooks/YippityUserContext";
import useCanCreateQuiz from "../../Hooks/useCanCreateQuiz";
import { toast } from "react-hot-toast";
import ReactFlagsSelect from "react-flags-select";
import { useFirebaseAuth } from "../../Hooks/FirebaseAuth";
import untruncateJson from "untruncate-json";
import {
  MdContentCopy,
  MdOutlineEdit,
  MdDone,
  MdDeleteOutline,
} from "react-icons/md";
import { VscDebugRestart } from "react-icons/vsc";

import { AiFillSave } from "react-icons/ai";

const characterLimit = 10000;
const placeholder =
  "Example: Vietnam War, (1954–75), a protracted conflict that pitted the communist government of North Vietnam and its allies in South Vietnam, known as the Viet Cong, against the government of South Vietnam and its principal ally, the United States. Called the “American War” in Vietnam (or, in full, the “War Against the Americans to Save the Nation”), the war was also part of a larger regional conflict (see Indochina wars) and a manifestation of the Cold War between the United States and the Soviet Union and their respective allies. \n\n At the heart of the conflict was the desire of North Vietnam, which had defeated the French colonial administration of Vietnam in 1954, to unify the entire country under a single communist regime modeled after those of the Soviet Union and China. The South Vietnamese government, on the other hand, fought to preserve a Vietnam more closely aligned with the West. U.S. military advisers, present in small numbers throughout the 1950s, were introduced on a large scale beginning in 1961, and active combat units were introduced in 1965. By 1969 more than 500,000 U.S. military personnel were stationed in Vietnam. Meanwhile, the Soviet Union and China poured weapons, supplies, and advisers into the North, which in turn provided support, political direction, and regular combat troops for the campaign in the South. ";

const countryToLanguage: { [countryCode: string]: string } = {
  GB: "EN",
  // FR: "FR",
  // DE: "DE",
  IT: "IT",
  ES: "ES",
};

export enum QuestionType {
  OPEN_ENDED,
  MULTIPLE_CHOICE,
}

type Question = {
  question: string;
  answer: string;
  options?: string[];
};

function UrlFetch({
  generateResponse,
}: {
  generateResponse: (url?: string, query?: string) => Promise<void>;
}) {
  const [url, setUrl] = useState("");
  const { logEvent } = useContext(GoogleAnalyticsContext);
  const navigate = useNavigate();
  const canCreateQuiz = useCanCreateQuiz();

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();
    if (!canCreateQuiz) {
      return navigate("/pricing");
    }
    logEvent("Query", "URL Generate", url);
    await generateResponse(url);
  };

  return (
    <TextInput
      placeholder="https://en.wikipedia.org/wiki/Vietnam_War"
      onChange={(e) => setUrl(e.target.value)}
      value={url}
      logo={
        url && (
          <BsFillArrowRightCircleFill
            onClick={handleSubmit}
            className={styles.iconButton}
          />
        )
      }
    />
  );
}

function flattenQuiz(
  generatedQuiz: {
    questions_and_answers: {
      question: string;
      answer: string;
    }[];
  }[]
): {
  question: string;
  answer: string;
}[] {
  return generatedQuiz
    .map((quiz) => {
      return quiz.questions_and_answers;
    })
    .flat();
}

function QuestionAnswerCard({
  isLoading,
  onClickDelete,
  setQuestion,
  setAnswer,
  questionType,
  question,
}: {
  question: Question;
  isLoading: boolean;
  onClickDelete: () => void;
  setQuestion: (question: string) => void;
  setAnswer: (answer: string) => void;
  questionType: QuestionType;
}) {
  const [editingMode, setEditingMode] = useState(false);
  const { logEvent } = useContext(GoogleAnalyticsContext);

  // This function formats the question, options (when available) and answer in a way that can be copied to the clipboard
  function formatForCopy() {
    let finalText = question.question;
    if (question.options && questionType == QuestionType.MULTIPLE_CHOICE) {
      finalText += "\n";
      for (let i = 0; i < question.options.length; i++) {
        finalText +=
          "\n" + String.fromCharCode(65 + i) + ") " + question.options[i];
      }
    }
    if (question.answer) {
      finalText += "\n\nAnswer: " + question.answer;
    }
    return finalText;
  }

  const onClickCopy = async (e: any) => {
    e.preventDefault();
    const finalText = formatForCopy();
    await navigator.clipboard.writeText(finalText);
    toast.success("Copied question and answer to clipboard");
    logEvent("Interaction", "Copy");
  };

  const onClickEdit = (e: any) => {
    e.preventDefault();
    setEditingMode(true);
    logEvent("Interaction", "Edit");
  };

  const onClickDone = (e: any) => {
    e.preventDefault();
    setEditingMode(false);
    logEvent("Interaction", "Done");
  };

  const onEditingModeIcons = (
    <MdDone size={24} onClick={onClickDone} className={QuizStyles.iconButton} />
  );

  const nonEditingModeIcons = !isLoading && (
    <>
      <MdContentCopy className={QuizStyles.iconButton} onClick={onClickCopy} />
      <Spacer width="8px" />
      <Spacer width="8px" />
      <MdOutlineEdit className={QuizStyles.iconButton} onClick={onClickEdit} />
      <Spacer width="8px" />
      <MdDeleteOutline
        className={QuizStyles.iconButton}
        onClick={onClickDelete}
      />
    </>
  );

  return (
    <VerticalFlexContainer
      backgroundColor="#FAFAFF"
      padding="20px"
      borderRadius="10px"
      marginBottom="10px"
      width="100%"
      alignItems="flex-start"
      justifyContent="flex-start"
      boxShadow="0px 0px 10px rgba(0, 0, 0, 0.1)"
    >
      <HorizontalFlexContainer justifyContent="space-between" width="100%">
        {editingMode ? (
          <textarea
            className={QuizStyles.editingField}
            value={question?.question || ""}
            onChange={(e) => setQuestion(e.target.value)}
          />
        ) : (
          <p className={QuizStyles.questionText}>{question?.question}</p>
        )}
        <HorizontalFlexContainer>
          {!editingMode && nonEditingModeIcons}
        </HorizontalFlexContainer>
      </HorizontalFlexContainer>
      {question?.options && questionType == QuestionType.MULTIPLE_CHOICE && (
        <VerticalFlexContainer>
          <Spacer height="10px" />
          <ul className={QuizStyles.optionsList}>
            {question?.options.map((option) => {
              return (
                <li className={QuizStyles.optionsListItem}>
                  <p>{option}</p>
                </li>
              );
            })}
          </ul>
        </VerticalFlexContainer>
      )}
      <Spacer height="10px" />
      {question?.answer &&
        (editingMode ? (
          <textarea
            className={QuizStyles.editingField}
            value={question?.answer || ""}
            onChange={(e) => setAnswer(e.target.value)}
          />
        ) : (
          <p className={QuizStyles.resultText}>
            <strong>Answer:</strong> {question?.answer}
          </p>
        ))}
      {editingMode && (
        <>
          <Spacer height="8px" />
          <HorizontalFlexContainer width="100%" justifyContent="flex-end">
            {onEditingModeIcons}
          </HorizontalFlexContainer>
        </>
      )}
    </VerticalFlexContainer>
  );
}

function Quiz({
  generatedQuiz,
  isLoadingQuiz,
  restart,
  questionType,
}: {
  generatedQuiz: Question[];
  isLoadingQuiz: boolean;
  restart: () => void;
  questionType: QuestionType;
}) {
  const { refetch } = useContext(YippityUserContext);
  const navigate = useNavigate();
  const { logEvent } = useContext(GoogleAnalyticsContext);

  const [quiz, setQuiz] = useState(generatedQuiz);
  const { res, callAPI, isLoading } = useAuthenticatedFetch({
    url: config.API_URI + `/quiz`,
    method: "POST",
  });
  useEffect(() => {
    setQuiz(generatedQuiz);
  }, [generatedQuiz]);

  const onSave = async () => {
    logEvent("Query", "Save Quiz");

    callAPI(JSON.stringify({ questionsAndAnswers: quiz }));
  };

  const onRestart = () => {
    logEvent("Query", "Restart Quiz");
    restart();
  };

  useEffect(() => {
    if (res.statusCode === 200) {
      refetch();
      navigate(`/quiz/${res.data.quizId}`);
    }
  }, [res]);

  return (
    <VerticalFlexContainer width="100%">
      <VerticalFlexContainer width="100%" alignItems="flex-end">
        <HorizontalFlexContainer
          backgroundColor="#FAFAFF"
          padding="10px"
          borderRadius="10px"
          marginBottom="10px"
          maxWidth="200px"
          alignItems="center"
          justifyContent="center"
          boxShadow="0px 0px 10px rgba(0, 0, 0, 0.1)"
        >
          {isLoadingQuiz ? (
            <HashLoader color="#FFC60B" size={20} />
          ) : (
            <HorizontalFlexContainer>
              {questionType == QuestionType.OPEN_ENDED && (
                <>
                  <AiFillSave
                    className={QuizStyles.iconButton}
                    onClick={onSave}
                  />
                  <Spacer width="8px" />
                </>
              )}
              <VscDebugRestart
                className={QuizStyles.iconButton}
                onClick={onRestart}
              />
            </HorizontalFlexContainer>
          )}
        </HorizontalFlexContainer>
      </VerticalFlexContainer>
      {quiz.map((qa, i) => {
        return (
          <QuestionAnswerCard
            question={qa}
            onClickDelete={() => {
              const newQuiz = [...quiz];
              newQuiz.splice(i, 1);
              setQuiz(newQuiz);
            }}
            isLoading={isLoadingQuiz}
            setQuestion={(question) => {
              const newQuiz = [...quiz];
              newQuiz[i].question = question;
              setQuiz(newQuiz);
            }}
            setAnswer={(answer) => {
              const newQuiz = [...quiz];
              newQuiz[i].answer = answer;
              setQuiz(newQuiz);
            }}
            questionType={questionType}
          />
        );
      })}
    </VerticalFlexContainer>
  );
}

function Home() {
  const [query, setQuery] = useState("");
  const { logEvent } = useContext(GoogleAnalyticsContext);
  const { refetch } = useContext(YippityUserContext);

  const canCreateQuiz = useCanCreateQuiz();
  const [countryCode, setCountryCode] = useState("GB");
  const user = useFirebaseAuth();
  const [isLoadingQuiz, setIsLoadingQuiz] = useState(false);
  const [quiz, setQuiz] = useState<Question[]>([]);
  const [quizType, setQuizType] = useState<QuestionType>(
    QuestionType.OPEN_ENDED
  );

  const navigate = useNavigate();
  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();
    logEvent("Query", "Generate", query);
    await generateResponse(undefined, query);
  };

  const generateResponse = async (url?: string, query?: string) => {
    if (!canCreateQuiz) {
      return navigate("/pricing");
    }
    setIsLoadingQuiz(true);
    setQuiz([]);
    if (!user) {
      return;
    }

    const response = await fetch(config.API_URI + "/stream-quiz", {
      method: "POST",
      headers: {
        authorization: `Bearer ${await user.getIdToken()}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        sourceURI: url,
        language: countryToLanguage[countryCode],
        query: query,
        questionType: quizType,
      }),
    });

    await refetch();

    if (response.status != 200) {
      toast.error(
        "We had a problem reading your input please make sure it is a valid one!"
      );
    }
    const responseData = response.body;
    if (!responseData) {
      return;
    }
    const reader = responseData.getReader();
    const decoder = new TextDecoder();
    let done = false;

    let buffer = "";

    while (!done) {
      const { value, done: doneReading } = await reader.read();
      done = doneReading;
      const chunkValue = decoder.decode(value);
      buffer = buffer + chunkValue;
      try {
        const parsedBuffer = JSON.parse(untruncateJson(buffer));
        setQuiz(flattenQuiz(parsedBuffer));
      } catch (e) {
        console.log(e);
      }
    }

    setIsLoadingQuiz(false);
  };

  useEffect(() => {
    if (countryCode != "GB") {
      logEvent("Interaction", "Language", countryToLanguage[countryCode]);
    }
  }, [countryCode]);

  return (
    <PageWrapper>
      <NavigationBar />
      <VerticalFlexContainer
        alignItems="center"
        flex={1}
        paddingLeft="8%"
        paddingRight="8%"
        paddingBottom="20px"
      >
        <VerticalFlexContainer
          maxWidth="600px"
          alignItems="center"
          width="100%"
        >
          <Spacer height="10vh" />

          {quiz.length == 0 && !isLoadingQuiz ? (
            <>
              <TitleText>
                Enter your <span className="excel"> Notes</span> or any{" "}
                <span className="excel"> URL </span>and Yip 🤖 will generate a
                quiz automatically from them
              </TitleText>
              <Spacer height="3vh" />
              <HorizontalFlexContainer
                maxWidth="400px"
                width="100%"
                justifyContent="flex-start"
              >
                <ReactFlagsSelect
                  countries={Object.keys(countryToLanguage)}
                  customLabels={countryToLanguage}
                  selected={countryCode}
                  onSelect={setCountryCode}
                  selectedSize={14}
                  optionsSize={14}
                  className={styles.languagePicker}
                />
                <Spacer width="10px" />
                <select
                  value={quizType}
                  onChange={(e: any) => {
                    logEvent("Interaction", "Question Type", e.target.value);
                    setQuizType(parseInt(e.target.value) as QuestionType);
                  }}
                  className={styles.questionTypePicker}
                >
                  <option value={QuestionType.OPEN_ENDED}>Open Ended</option>
                  <option value={QuestionType.MULTIPLE_CHOICE}>
                    Multiple Choice
                  </option>
                </select>
              </HorizontalFlexContainer>
              <Spacer height="1vh" />
              <UrlFetch generateResponse={generateResponse} />
              <Spacer height="3vh" />
              <HorizontalFlexContainer width="100%" alignItems="center">
                <HorizontalFlexContainer
                  borderBottom="1px solid #3c4250"
                  width="100%"
                  flex={1}
                  marginRight="4px"
                >
                  <></>
                </HorizontalFlexContainer>
                <span
                  style={{
                    fontWeight: "700",
                    color: "#3c4250",
                  }}
                >
                  OR
                </span>
                <HorizontalFlexContainer
                  borderBottom="1px solid #3c4250"
                  width="100%"
                  flex={1}
                  marginLeft="4px"
                >
                  <></>
                </HorizontalFlexContainer>
              </HorizontalFlexContainer>

              <Spacer height="3vh" />
              <div className={styles.webflowStyleInput}>
                <textarea
                  placeholder={placeholder}
                  rows={20}
                  cols={40}
                  value={query}
                  maxLength={characterLimit}
                  onChange={(e) => setQuery(e.target.value)}
                  className={styles.promptTextArea}
                ></textarea>
              </div>
              <HorizontalFlexContainer
                width="100%"
                height="40px"
                justifyContent="space-between"
                alignItems="center"
              >
                <p className={styles.counter}>
                  {query.length}/{characterLimit}
                </p>
                {query && <Button onClick={handleSubmit}>Generate</Button>}
              </HorizontalFlexContainer>
            </>
          ) : (
            <Quiz
              generatedQuiz={quiz}
              isLoadingQuiz={isLoadingQuiz}
              restart={() => {
                setQuiz([]);
              }}
              questionType={quizType}
            />
          )}
        </VerticalFlexContainer>
      </VerticalFlexContainer>
    </PageWrapper>
  );
}

export default Home;
