import { createContext, useEffect, useState } from "react";

export const ShowEmailFormContext = createContext({
  usageCount: 0,
  incrementUsageCount: () => {},
});

export function ShowEmailFormContextProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [usageCount, setUsageCount] = useState(0);

  const incrementUsageCount = () => {
    setUsageCount(usageCount + 1);
    localStorage.setItem("usageCount", JSON.stringify(usageCount));
  };

  useEffect(() => {
    const currentCount = Number(localStorage.getItem("usageCount") || 0);

    if (currentCount) {
      setUsageCount(currentCount);
    }
  }, []);

  return (
    <ShowEmailFormContext.Provider
      value={{
        incrementUsageCount,
        usageCount,
      }}
    >
      {children}
    </ShowEmailFormContext.Provider>
  );
}
