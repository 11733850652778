import { useContext, useEffect } from "react";
import { Container, Nav, Navbar, NavbarBrand } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { SocialIcon } from "react-social-icons";
import { auth } from "../../../config/firebase-config";
import { useFirebaseAuth } from "../../../Hooks/FirebaseAuth";
import { YippityUserContext } from "../../../Hooks/YippityUserContext";
import Button from "../../Input/Button/Button";
import HorizontalFlexContainer from "../../Layout/HorizontalFlexContainer/HorizontalFlexContainer";
import Spacer from "../../Layout/Spacer/Spacer";
import MainText from "../../Text/MainText/MainText";
import Logo from "../Logo/Logo";
import TrialCounter from "../TrialCounter/TrialCounter";
import config from "../../../config";
import { useAuthenticatedFetch } from "../../../Hooks/useFetch";
import { GoogleAnalyticsContext } from "../../../Hooks/GoogleAnalyticsContext";

function NavigationBar() {
  const navigate = useNavigate();
  const firebaseUser = useFirebaseAuth();
  const { user, isSubscribed } = useContext(YippityUserContext);
  const { res, callAPI, isLoading } = useAuthenticatedFetch({
    url: config.API_URI + `/create-billing-portal-session`,
    method: "POST",
  });

  const { logEvent } = useContext(GoogleAnalyticsContext);

  const getBillingPortalSession = async () => {
    logEvent("Interaction", "Portal Session");
    callAPI();
  };

  useEffect(() => {
    if (res.statusCode === 200) {
      window.open(res.data.url, "_self");
    }
  }, [res]);
  const isSignedIn = !!firebaseUser && !!user;

  return (
    <Navbar collapseOnSelect expand="lg" bg="transparent" variant="light">
      <Container>
        <NavbarBrand href={isSignedIn ? "/home" : "/"}>
          <Logo size={"80px"} />
        </NavbarBrand>
        {!isSubscribed && <TrialCounter />}
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto"></Nav>
          <Nav>
            {!isSignedIn ? (
              <>
                <Link
                  to="/signin"
                  style={{
                    textDecoration: "none",
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <MainText>Sign in</MainText>
                  <Spacer width="10px" />
                </Link>
                <Link
                  to="/signup"
                  style={{
                    textDecoration: "none",
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <Button onClick={(e) => {}}>Sign up</Button>
                  <Spacer width="20px" />
                </Link>
              </>
            ) : (
              <HorizontalFlexContainer
                justifyContent="center"
                alignItems="center"
              >
                {isSubscribed ? (
                  <HorizontalFlexContainer onClick={getBillingPortalSession}>
                    <MainText>Account</MainText>
                  </HorizontalFlexContainer>
                ) : (
                  <Button
                    onClick={() => {
                      navigate("/pricing");
                    }}
                    height="35px"
                  >
                    Subscribe
                  </Button>
                )}
                <Spacer width="20px" />

                <HorizontalFlexContainer
                  onClick={() => {
                    navigate("/quiz");
                  }}
                >
                  <MainText>My Quizzes</MainText>
                </HorizontalFlexContainer>
                <Spacer width="20px" />
                <HorizontalFlexContainer
                  onClick={() => {
                    auth.signOut();
                    localStorage.clear();
                    navigate("/");
                  }}
                >
                  <MainText>Sign out</MainText>
                </HorizontalFlexContainer>
                <Spacer width="20px" />
              </HorizontalFlexContainer>
            )}
            <HorizontalFlexContainer
              justifyContent="center"
              alignItems="center"
            >
              <SocialIcon
                bgColor="rgba(0,0,0,0)"
                fgColor="#000000"
                url="https://twitter.com/JuanTwoJuan_"
              />
            </HorizontalFlexContainer>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavigationBar;
