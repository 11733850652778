import { useContext } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { useFirebaseAuth } from "./Hooks/FirebaseAuth";
import { YippityUserContext } from "./Hooks/YippityUserContext";
import Authenticated from "./Routes/Authenticated/Authenticated";
import Home from "./Routes/Home/Home";
import LandingPage from "./Routes/LandingPage/LandingPage";
import Login from "./Routes/Login/Login";
import Success from "./Routes/Payment/Success";
import Pricing from "./Routes/Pricing/Pricing";
import Quiz from "./Routes/Quiz/Quiz";
import Quizzes from "./Routes/Quizzes/Quizzes";
import Register from "./Routes/Register/Register";
import Report from "./Routes/Report/Report";
import Test from "./Routes/Test/Test";
import Welcome from "./Routes/Welcome/Welcome";

export default function RouterEntry() {
  const firebaseUser = useFirebaseAuth();
  const { user } = useContext(YippityUserContext);

  const isSignedIn = !!firebaseUser;
  const isSignedInYippityUser = !!firebaseUser && !!user;

  if (isSignedInYippityUser) {
    return (
      <Routes>
        <Route path="/home" element={<Home />} />
        <Route path="/authenticated" element={<Authenticated />} />
        <Route path="/quiz/:quizId" element={<Quiz />} />
        <Route path="/quiz/:quizId/test" element={<Test />} />
        <Route path="/quiz/:quizId/report/:reportId" element={<Report />} />
        <Route path="/" element={<Home />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/payment/success" element={<Success />} />
        <Route path="/quiz" element={<Quizzes />} />
      </Routes>
    );
  }

  if (isSignedIn) {
    return (
      <Routes>
        <Route path="/authenticated" element={<Authenticated />} />
        <Route path="/home" element={<Home />} />
        <Route path="/welcome" element={<Welcome />} />
        <Route path="/quiz/:quizId" element={<Quiz />} />
      </Routes>
    );
  }

  return (
    <Routes>
      <Route path="/" element={<LandingPage />} />
      <Route path="/signup" element={<Register />}></Route>
      <Route path="/signin" element={<Login />} />
      <Route path="/authenticated" element={<Authenticated />} />
      <Route path="/quiz/:quizId" element={<Quiz />} />
    </Routes>
  );
}
