import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PageWrapper from "../../Components/Layout/PageWrapper/PageWrapper";
import config from "../../config";
import { useFirebaseAuth } from "../../Hooks/FirebaseAuth";
import { useAuthenticatedFetch } from "../../Hooks/useFetch";

export default function Authenticated() {
  const user = useFirebaseAuth();
  const navigate = useNavigate();
  const { res, callAPI } = useAuthenticatedFetch({
    url: config.API_URI + `/user`,
    method: "GET",
  });

  useEffect(() => {
    callAPI();
  }, [user]);

  useEffect(() => {
    if (res.statusCode === 404) {
      navigate("/welcome");
    }

    if (res.statusCode === 200) {
      navigate("/home");
    }
  }, [res]);

  return <PageWrapper></PageWrapper>;
}
