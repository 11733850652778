import React from "react";
import styles from "./HorizontalFlexContainer.module.css";

const HorizontalFlexContainer: React.FC<FlexContainer> = ({
  children,
  className,
  onClick,
  ...props
}) => {
  return (
    <div
      className={[styles.container, className ?? ""].join(" ")}
      style={{ ...props, cursor: onClick ? "pointer" : "" }}
      onClick={onClick}
    >
      {children}
    </div>
  );
};

export default HorizontalFlexContainer;
