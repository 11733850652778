// FirebaseAuthContext.tsx
import * as React from "react";
import { auth } from "../config/firebase-config";
import { User as FirebaseUser } from "firebase/auth";
type User = FirebaseUser | null;
type ContextState = { user: User };

const FirebaseAuthContext = React.createContext<ContextState | undefined>(
  undefined
);

type Props = {
  children?: React.ReactNode;
};
export const FirebaseAuthProvider: React.FC<Props> = ({ children }) => {
  const [user, setUser] = React.useState<User>(null);
  const value = { user };

  React.useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(setUser);
    return unsubscribe;
  }, []);

  return (
    <FirebaseAuthContext.Provider value={value}>
      {children}
    </FirebaseAuthContext.Provider>
  );
};

export function useFirebaseAuth() {
  const context = React.useContext(FirebaseAuthContext);
  if (context === undefined) {
    throw new Error(
      "useFirebaseAuth must be used within a FirebaseAuthProvider"
    );
  }
  return context.user;
}
