import React, { createContext, useEffect } from "react";
import ReactGA from "react-ga4";
import { analytics } from "../config/firebase-config";
import { logEvent as logGAEvent } from "firebase/analytics";
import { useFirebaseAuth } from "./FirebaseAuth";

export const GoogleAnalyticsContext = createContext({
  logEvent: (category: string, action: string, label?: string) => {},
});

export function GoogleAnalyticsContextProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const user = useFirebaseAuth();

  useEffect(() => {
    ReactGA.initialize("G-ET0PG2LN4Z", {
      gaOptions: {
        user_id: user?.uid,
      },
    });
  }, []);

  useEffect(() => {
    if (user?.uid) {
      ReactGA.set({
        user_id: user.uid,
      });
    }
  }, [user]);

  const logEvent = (category: string, action: string, label?: string) => {
    logGAEvent(analytics, category, {
      action,
      label,
    });
    ReactGA.event({
      category,
      action,
      label,
    });
  };

  return (
    <GoogleAnalyticsContext.Provider
      value={{
        logEvent,
      }}
    >
      {children}
    </GoogleAnalyticsContext.Provider>
  );
}
