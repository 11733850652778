import React from "react";
import styles from "./TextInput.module.css";
interface TextInputProps {
  placeholder: string;
  value: string;
  type?: string;
  onChange: (e: any) => void;
  logo?: any;
}

const TextInput: React.FC<TextInputProps> = (props) => {
  return (
    <div className={styles.webflowStyleInputContainer}>
      <input
        placeholder={props.placeholder}
        value={props.value}
        onChange={props.onChange}
        type={props.type}
        className={styles.webflowStyleInput}
      />
      {props.logo && <div className={styles.logoContainer}>{props.logo}</div>}
    </div>
  );
};

export default TextInput;
