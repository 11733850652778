import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import NavigationBar from "../../Components/HighLevelComponents/NavigationBar/NavigationBar";
import Button from "../../Components/Input/Button/Button";
import HorizontalFlexContainer from "../../Components/Layout/HorizontalFlexContainer/HorizontalFlexContainer";
import PageWrapper from "../../Components/Layout/PageWrapper/PageWrapper";
import Spacer from "../../Components/Layout/Spacer/Spacer";
import VerticalFlexContainer from "../../Components/Layout/VerticalFlexContainer/VerticalFlexContainer";
import MainText from "../../Components/Text/MainText/MainText";
import TitleText from "../../Components/Text/TitleText/TitleText";
import config from "../../config";
import { useFetch, useAuthenticatedFetch } from "../../Hooks/useFetch";
import { FaArrowLeft } from "react-icons/fa";
import { YippityUserContext } from "../../Hooks/YippityUserContext";
import useCanCreateQuiz from "../../Hooks/useCanCreateQuiz";
import { GoogleAnalyticsContext } from "../../Hooks/GoogleAnalyticsContext";
import Tippy from "@tippyjs/react";
import { AiOutlineQuestionCircle } from "react-icons/ai";

interface QuestionAndAnswer {
  questionText: string;
  answerText: string;
  canEdit: boolean;
  id: number;
}

interface Quiz {
  name: string;
  authorId: number;
  id: number;
}

function Question({
  question,
  setStudentAnswer,
  studentAnswer,
}: {
  question: string;
  setStudentAnswer: (answer: string) => void;
  studentAnswer: string;
}) {
  return (
    <VerticalFlexContainer
      backgroundColor="rgba(0, 0, 0, 0.05)"
      padding="20px"
      borderRadius="10px"
      marginBottom="10px"
      width="100%"
      alignItems="flex-start"
      justifyContent="flex-start"
    >
      <MainText fontWeight={700} fontSize="16px">
        {question}
      </MainText>
      <textarea
        style={{ width: "100%" }}
        value={studentAnswer}
        onChange={(e) => setStudentAnswer(e.target.value)}
      />
    </VerticalFlexContainer>
  );
}

function Test() {
  const { quizId } = useParams();

  const [questions, setQuestions] = useState<string[]>([]);
  const [quiz, setQuiz] = useState<Quiz>();
  const navigate = useNavigate();
  const { refetch } = useContext(YippityUserContext);
  const canCreateQuiz = useCanCreateQuiz();
  const [studentAnswers, setStudentAnswers] = useState<string[]>([]);
  const { logEvent } = useContext(GoogleAnalyticsContext);

  const { res, callAPI } = useFetch({
    url: config.API_URI + `/quiz/${quizId}`,
    method: "GET",
  });

  const {
    res: automarkQuizReponse,
    callAPI: automarkQuiz,
    isLoading,
  } = useAuthenticatedFetch({
    url: config.API_URI + `/quiz/${quizId}/automark`,
    method: "POST",
  });

  useEffect(() => {
    callAPI();
  }, []);

  useEffect(() => {
    if (res.statusCode === 200) {
      const { questions, quiz } = res.data;
      setQuestions(questions.map((q: QuestionAndAnswer) => q.questionText));
      setQuiz(quiz);
      setStudentAnswers(questions.map(() => ""));
    }
  }, [res]);

  useEffect(() => {
    if (automarkQuizReponse.statusCode === 200) {
      refetch();
      logEvent("Interaction", "Report");
      navigate(`/quiz/${quizId}/report/${automarkQuizReponse.data.report.id}`);
    }
  }, [automarkQuizReponse]);

  const setStudentAnswer = (index: number, answer: string) => {
    const copy = [...studentAnswers];
    copy[index] = answer;
    setStudentAnswers(copy);
  };

  const submitTest = () => {
    logEvent("Interaction", "Automark");
    if (!canCreateQuiz) {
      return navigate("/pricing");
    }
    automarkQuiz(JSON.stringify({ studentAnswers }));
  };

  return (
    <PageWrapper noBackground>
      <NavigationBar />
      <VerticalFlexContainer alignItems="center" padding="20px" width="100%">
        <VerticalFlexContainer maxWidth="700px">
          <HorizontalFlexContainer
            alignItems="center"
            justifyContent="space-between"
          >
            <HorizontalFlexContainer
              alignItems="center"
              cursor="pointer"
              onClick={() => {
                navigate(`/quiz/${quizId}`);
              }}
            >
              <FaArrowLeft />
              <Spacer width="4px" />
              <MainText fontWeight={500}>Back to Quiz</MainText>
            </HorizontalFlexContainer>
            <HorizontalFlexContainer>
              <Tippy
                theme="light"
                content={
                  <div>
                    <MainText>
                      With test mode, we use AI to automark your quiz and give
                      you a score. Simply answer every question in the quiz and
                      click "Submit" and we will take care of the rest!
                    </MainText>
                  </div>
                }
              >
                <div
                  style={{
                    alignItems: "center",
                    flexDirection: "row",
                    display: "flex",
                  }}
                >
                  <MainText fontSize="10px">What is test mode?</MainText>
                  <AiOutlineQuestionCircle size={14} color="#3c4250" />
                </div>
              </Tippy>
            </HorizontalFlexContainer>
          </HorizontalFlexContainer>
          <Spacer height="12px" />
          <TitleText textAlign={"left"}>{quiz?.name}</TitleText>
          <Spacer height="12px" />
          {questions.map((question, i) => (
            <Question
              key={i}
              question={question}
              studentAnswer={studentAnswers[i]}
              setStudentAnswer={(val: string) => setStudentAnswer(i, val)}
            />
          ))}
          <Spacer height="12px" />
          <VerticalFlexContainer width="100%" alignItems="flex-end">
            <Button
              onClick={submitTest}
              disabled={
                !studentAnswers
                  .map((item) => {
                    return item.length > 0;
                  })
                  .every((item) => {
                    return item;
                  })
              }
              loading={isLoading}
            >
              Submit
            </Button>
          </VerticalFlexContainer>
        </VerticalFlexContainer>
      </VerticalFlexContainer>
    </PageWrapper>
  );
}

export default Test;
